export default [
  {
    timestamp: 1627660800000,
    clusterPoints: [
      { price: 42661.56, volume: 0.1104, percent: 17.639164057007736 },
      { price: 42661.38, volume: 0.00062, percent: 0.09906052278392027 },
      { price: 42661.16, volume: 0.5, percent: 79.88751837412924 },
      { price: 42661.14, volume: 0.00046, percent: 0.07349651690419891 },
      { price: 42661.13, volume: 0.0144, percent: 2.300760529174922 },
    ],
  },
  {
    timestamp: 1627660800000,
    clusterPoints: [
      { price: 42663.11, volume: 0.00459, percent: 3.6244472520530646 },
      { price: 42662.94, volume: 0.00062, percent: 0.4895767530006318 },
      { price: 42662.68, volume: 0.00062, percent: 0.4895767530006318 },
      { price: 42662.61, volume: 0.001, percent: 0.7896399241945674 },
      { price: 42662.42, volume: 0.00062, percent: 0.4895767530006318 },
      { price: 42662.16, volume: 0.00062, percent: 0.4895767530006318 },
      { price: 42662.15, volume: 0.00013, percent: 0.10265319014529375 },
      { price: 42661.9, volume: 0.00062, percent: 0.4895767530006318 },
      { price: 42661.64, volume: 0.11782, percent: 93.03537586860394 },
    ],
  },
  {
    timestamp: 1627660800000,
    clusterPoints: [
      { price: 42663.33, volume: 0.00232, percent: 3.486624586714758 },
      { price: 42663.32, volume: 0.05026, percent: 75.53351367598437 },
      { price: 42663.2, volume: 0.00758, percent: 11.391644123835288 },
      { price: 42663.12, volume: 0.0063, percent: 9.467989179440938 },
      { price: 42663.11, volume: 0.00008, percent: 0.12022843402464685 },
    ],
  },
  {
    timestamp: 1627660800000,
    clusterPoints: [
      { price: 42663.34, volume: 0.30557999999999996, percent: 71.25069949636263 },
      { price: 42663.33, volume: 0.11848, percent: 27.625443014363 },
      { price: 42663.32, volume: 0.00482, percent: 1.123857489274389 },
    ],
  },
]
