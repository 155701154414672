<script setup lang="ts">
</script>

<template>
  <main>
    <n-list>
      <n-list-item>
        <router-link to="/scalper">Scalper</router-link>
      </n-list-item>
      <n-list-item>
        <router-link to="/create-order">Create order</router-link>
      </n-list-item>
      <n-list-item>
        <router-link to="/orderbook">OrderBook</router-link>
      </n-list-item>
      <n-list-item>
        <router-link to="/candles">Candles</router-link>
      </n-list-item>
      <n-list-item>
        <router-link to="/clusters">Clusters</router-link>
      </n-list-item>
      <n-list-item>
        <router-link to="/exchanges">Exchanges</router-link>
      </n-list-item>
      <n-list-item>
        <router-link to="/trades">Trades</router-link>
      </n-list-item>
    </n-list>
  </main>
</template>
