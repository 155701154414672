<template>
  <div class="ticks">
    <!-- {{data}} -->
    {{store.demo}}
    <!-- {{ store.sliderValue }} -->
    <!-- {{store.historyEnabled}} -->
    <!-- {{ data.length }} -->
    <svg ref="svgRef" class="svg">
    </svg>
  </div>
</template>

<script lang="ts" setup>
// import { Candle } from '@/types'
import demoData from './data'
import { ref, toRefs, onMounted, onUnmounted, reactive, watch } from 'vue'
import {
  select,
  line,
  scaleLinear,
  min,
  max,
  curveBasis,
  axisBottom,
  axisLeft,
} from "d3"
import { useMainStore } from '@/stores/main';
// spreadPrice, minPriceStep, priceStep from useMainStore

const props = defineProps({
  widgetRef: Object,
  exchangeId: String,
  baseId: String,
  quoteId: String,
  timeframe: String,
  lastN: Number,
})

const { exchangeId, baseId, quoteId, timeframe, lastN } = toRefs(props);

interface LineData {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  style: string;
}

const store = useMainStore();
const data = ref(store.demo ? demoData : []) // demoData;
const demo = ref(false);

// import demoCandles from './data'
const svgRef: any = ref(null);

const color = ref()
const colorGrey = ref()
const svgWidthRef = ref()
const svgHeightRef = ref()

// onMounted
// const scaleFactor = 0.01; // Adjust the scale factor as needed

const redraw = () => {
  color.value = 'rgb(38, 57, 53)'
  colorGrey.value = '#bebebe'
  const scaleFactor = 50; // Adjust the scale factor as needed
  const svg: any = select(svgRef.value);
  let svgWidth: number = svgRef?.value?.clientWidth || 0;
  let svgHeight: number = svgRef?.value?.clientHeight || 0;
  svgWidthRef.value = svgWidth;
  svgHeightRef.value = svgHeight;
  const baseHeight = svgHeight / 2
  const pointHeight = 34
  const pointHeightRadius = pointHeight / 2

  // Calculating ranges for vertical positioning
  const lowVal = min(data.value, d => d.low) || 0;
  const highVal = max(data.value, d => d.high) || 0;
  const yScale = scaleLinear()
    .domain([lowVal, highVal])
    .range([svgHeight, 0]);

  // Remove previous ellipses and lines
  svg.selectAll('ellipse').remove();
  svg.selectAll('line').remove();
  svg.selectAll('text').remove();

  let lastEllipseCenter = { x: 0, y: 0 };

  let linesData: LineData[] = []; // Store line data for later rendering
  data.value.forEach((tick, index) => {
    console.log({tick})
    const radius = Math.sqrt(tick.volume) * scaleFactor;
    const cx = (index + 1) * 50; // Adjust the distance between the ellipses as needed
    const cy = (yScale(tick.low) + yScale(tick.high)) / 2;

    // Store line data for later rendering
    if (index > 0) {
      linesData.push({
        x1: lastEllipseCenter.x,
        y1: lastEllipseCenter.y,
        x2: cx,
        y2: cy,
        style: `stroke: ${colorGrey.value}; stroke-width: 1`
      });
    }

    lastEllipseCenter = { x: cx, y: cy };
  });

  // Render lines before ellipses
  linesData.forEach(lineData => {
  svg.append('line')
    .attr('x1', lineData.x1)
    .attr('y1', lineData.y1)
    .attr('x2', lineData.x2)
    .attr('y2', lineData.y2)
    .attr('style', lineData.style);
  });

  // Render ellipses after lines
  data.value.forEach((tick, index) => {
    const price = (tick.high+tick.low)/2
    const radius = Math.sqrt(tick.volume) * scaleFactor;
    const cx = (index + 1) * 50; // Adjust the distance between the ellipses as needed
    const cy = (yScale(tick.low) + yScale(tick.high)) / 2;

    svg.append('ellipse')
      .attr('rx', radius)
      .attr('ry', radius)
      .attr('cx', cx)
      .attr('cy', cy)
      .attr('style', `fill: ${color.value}`)

      svg.append('text')
      .attr('x', cx)
      .attr('y', cy)
      .attr('text-anchor', 'middle') // Center the text
      .attr('dominant-baseline', 'middle') // Vertically center the text
      .text(price)
      .attr('style', `fill: #ffffff`)
  });
}
const fetchData = async () => {
  data.value = await store.fetchCandlesRest({exchangeId: exchangeId?.value, baseId: baseId?.value, quoteId: quoteId?.value, timeframe: timeframe?.value, type: 'ticks', lastN: store.ticksN || lastN?.value})
  redraw();
}

watch(() => [store.sliderValue, store.historyEnabled], async ([newSliderValue, newHistoryEnabled], [oldSliderValue, oldHistoryEnabled]) => {
  if (newHistoryEnabled && newSliderValue !== oldSliderValue) {
    await fetchData()
  }
});


onMounted(async () => {
  // Initial draw
  redraw();

  // Redraw on window resize
  window.addEventListener('resize', redraw);

  fetchData()
  setInterval(async () => {
    await fetchData()
  }, 1000)
})

onUnmounted(() => {
  // Remove event listener to avoid memory leaks
  window.removeEventListener('resize', redraw);
});

const changeColor = () => {
  const newColor = color.value === 'red' ? 'blue' : 'red';
  color.value = newColor;
  select(svgRef.value).selectAll('ellipse').attr('style', `fill: ${newColor}`);
  select(svgRef.value).selectAll('line').attr('style', `stroke: ${newColor}; stroke-width: 1`);
  if (color.value === 'red') { color.value = 'blue' } else { color.value = 'red' }
}

</script>

<style scoped lang="sass">
.ticks
  height: 100%
.svg
  height: 100%
  // min-height: 500px
  width: 100%
  // min-width: 500px
</style>
