<template>
  <div class="account">
    <div v-if="address" class="wallet-address">
      <div>{{address}}</div>
      <div class="tokens-number">
        <div>{{balance?.toFixed(2)}} USDT</div>
        <div @click="props.logout" class="logout">
          <n-button size="tiny">Logout</n-button>
        </div>
      </div>
    </div>
    <div v-else class="ask-to-login" @click="props.login">
      Login with Metamask
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  address: String,
  balance: Number,
  login: Function,
  logout: Function,
});
</script>

<style scoped lang="sass">
.account
  white-space: nowrap
  display: flex
  justify-content: center
  align-items: center
  margin-left: 30px
.ask-to-login
  cursor: pointer
.wallet-address
  display: flex
  flex-direction: column
.tokens-number
  white-space: nowrap
  display: flex
  justify-content: end
  align-items: center
  .n-icon
    margin-left: 4px
.logout
  margin-left: 8px
  position: relative
  top: -2px
</style>
