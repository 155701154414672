<template>
  <div class="widget">
    <div class="widget-header">
      {{exchangeId}}
    </div>
    <div class="widget-body" ref="widgetRef">
      <Scalper :widgetRef="widgetRef" :exchangeId="exchangeId" :baseId="baseId" :quoteId="quoteId" />
      <!-- <OrderBook class="scalper-item-component" :widgetRef="widgetRef" :exchangeId="exchangeId" :baseId="baseId" :quoteId="quoteId" /> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, toRefs } from 'vue';
import OrderBook from '../OrderBook/OrderBook.vue';
import Ticks from '../Ticks/Ticks.vue';
import Scalper from '../Scalper/Scalper.vue';

const props = defineProps({
  exchangeId: String,
  baseId: String,
  quoteId: String,
});

const { exchangeId, baseId, quoteId } = toRefs(props);

const widgetRef: any = ref(null);
</script>

<style scoped lang="sass">
.widget
  height: 100% // 500px
  width: 100% // 500px
  border: 3px solid black
  display: flex
  flex-direction: column
.widget-header
  font-weight: 14px
.widget-body
  flex: 1 0 auto
  overflow: auto
  height: 100% // 500px
  width: 100% // 500px
</style>
