import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router'
import { min } from 'd3';
import moment from 'moment';
// import { exchanges } from 'ccxt';

type Scalper = {
  spreadPrice?: number;
  clustersCenter?: number;
  orderBooksCenter?: number;
  ticksCenter?: number;
};

type Scalpers = {
  [key: string]: Scalper; // This is the index signature
};

type Dashboard = {
  id: number;
  name: string;
  widgets: {
    id: number;
    instrument: string;
    pair: string;
    exchange: string;
    component: string;
    api: string[];
  }[];
};

const formatTimeToTimeInSeconds = (timeString: string, lodash: boolean) => {
  const format = lodash ? 'DD.MM.YY_HH:mm:ss' : 'DD.MM.YY HH:mm:ss';
  const date = moment(timeString, format);
  return date.unix();
};

export const useMainStore = defineStore('main', {
  state: () => {
    const route = useRoute();
    const router = useRouter();
    if (!route.query.pairs || !route.query.exchanges || !route.query.active || !route.query.history || !route.query.priceStep || !route.query.rowHeight || !route.query.clustersN || !route.query.ticksN || !route.query.orderbook || !route.query.clusters || !route.query.ticks) {
      router.push({
        query: {
          ...route.query,
          pairs: route.query.pairs || 'BTC/USDT,ETH/USDT',
          exchanges: route.query.exchanges || 'binance,kucoin',
          active: route.query.active || 'BTC/USDT',
          history: route.query.history || 'false',
          priceStep: route?.query?.priceStep || "0.1",
          rowHeight: route.query.rowHeight || '35.4',
          clustersN: route.query.clustersN || '5',
          ticksN: route.query.ticksN || '10',
          orderbook: route.query.orderbook || 'true',
          clusters: route.query.clusters || 'true',
          ticks: route.query.ticks || 'true',
        }
      });
    }
    const scalpers: Scalpers = {};
    return {
      demo: JSON.parse((route?.query?.demo === 'true' || 'false') as string),
      pairs: (route.query.pairs as string).split(','),
      exchanges: (route.query.exchanges as string).split(','),
      scalpers,
      orderbooks: {},
      dashboardCenterPrice: 42663.32,
      orderBooksDemo: true,
      orderBooksRest: '',
      tradesDemo: true,
      tradesRest: '',
      ticksDemo: true,
      ticksRest: '',
      clustersDemo: true,
      clustersRest: '',
      minPriceStep: 0.01,
      priceStep: parseFloat((route?.query?.priceStep || "0.1") as string),
      rowHeight: parseFloat((route?.query?.rowHeight || "35.4") as string),
      maxSliderValue: Math.floor(+new Date() / 1000),
      minSliderValue: Math.floor(+new Date() / 1000) - 2 * 60, // TODO: 24 hours: Math.floor(+new Date() / 1000) - 24 * 60 * 60
      sliderValue: formatTimeToTimeInSeconds(route.query.time as string, true) || Math.floor(+new Date() / 1000),
      historyEnabled: (route.query.history as string) === 'true' ? true : false,
      clustersN: parseInt((route?.query?.clustersN || "5") as string),
      ticksN: parseInt((route?.query?.ticksN || "10") as string),
      orderbook: (route.query.orderbook as string).toLowerCase() === 'true',
      clusters: (route.query.clusters as string).toLowerCase() === 'true',
      ticks: (route.query.ticks as string).toLowerCase() === 'true',
    }
  },
  getters: {
    // tabs: (state) => state.dashboards.map((dashboard) => dashboard.id),
  },
  actions: {
    // setActiveTab(tab: string) {
    //   this.activeTab = tab;
    // },
    setSpreadPrice(id: string, price: number) {
      if (this.scalpers[id] === undefined) this.scalpers[id] = { spreadPrice: 0 };
      this.scalpers[id].spreadPrice = price;
    },
    toggleHistoryEnabled() {
      this.historyEnabled = !this.historyEnabled;
      const query = {
        ...this.router.currentRoute.value.query,
      };

      if (this.historyEnabled) {
        query.history = 'true';
      } else {
        query.history = 'false';
        delete query.time;
        this.sliderValue = Math.floor(+new Date() / 1000);
      }

      this.router.replace({ query });
    },
    setSliderValue(newValue: any, formatTooltip: string) {
      this.sliderValue = newValue;
      this.router.replace({
        query: {
          ...this.router.currentRoute.value.query,
          time: formatTooltip,
        }
      });
    },
    async fetchAllInstrumentsByPair(pair: string) {
      const token = sessionStorage.getItem('token'); // TODO: временное решение, надо перенести всю работу с данными в store
      const result = await axios.get(`${import.meta.env.VITE_API_URL}/instruments?pairId=${pair}`, {
        headers: {
          Authorization: token
        }
      });
      // console.log({result})
      return result.data;
    },
    async fetchOrderbooksRest({ exchangeId, baseId, quoteId, limit = 100 }: { exchangeId: string; baseId: string; quoteId: string, limit?: number }) {
      const token = sessionStorage.getItem('token');
      // Seconds to Milliseconds
      const timeTo = this.historyEnabled && this.sliderValue ? `timeTo=${this.sliderValue * 1000}` : '';
      const result = await axios.get(`${import.meta.env.VITE_API_URL}/orderbook?exchange=${exchangeId}&base=${baseId}&quote=${quoteId}&limit=${limit}&history=${this.historyEnabled}&${timeTo}`, {
        headers: {
          Authorization: token
        }
      });
      // console.log({result})
      return result.data;
    },
    async fetchCandlesRest({ exchangeId, baseId, quoteId, timeframe = '1m', type = 'candles', lastN = 10 }: { exchangeId?: string; baseId?: string; quoteId?: string; timeframe?: string; type?: string; lastN?: number }) {
      // console.log('fetchCandlesRest')
      // console.log({exchangeId, baseId, quoteId, timeframe, type, lastN})
      const token = sessionStorage.getItem('token');
      // Seconds to Milliseconds
      const timeTo = this.historyEnabled && this.sliderValue ? `timeTo=${this.sliderValue * 1000}` : '';
      const result = await axios.get(`${import.meta.env.VITE_API_URL}/candles?exchange=${exchangeId}&base=${baseId}&quote=${quoteId}&timeframe=${timeframe}&lastN=${lastN}&type=${type}&${timeTo}`, {
        headers: {
          Authorization: token
        }
      });
      // console.log({result})
      return result.data;
    },
    // async fetching() {
    //   // ... existing code ...
    // },
  },
});
