<template>
  <div>
    <PairsUi :data="data" :search="search" :setSearch="setSearch"/>
  </div>
</template>

<script setup lang="ts">
import PairsUi from './PairsUi.vue'
import { ref, computed, onMounted, watch } from 'vue'
import { demoData } from './data'
import _ from 'lodash'

const demoDataList = Object.values(demoData).sort((a: any, b: any) => {
  if (a.active && !b.active) {
    return -1
  }
  if (!a.active && b.active) {
    return 1
  }
  return 0
})

const search = ref("")

const setSearch = (newValue: any) => {
  search.value = newValue
}

const data = computed(() => {
  if (!search.value) {
    return demoDataList
  }
  const filteredData = demoDataList.filter((item: any) =>
    item.id.toString().toLowerCase().includes(search.value.toLowerCase())
  );
  return filteredData;
})

</script>
