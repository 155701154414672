<template>
  <div>
  <!-- title="Create order" -->
  <n-card content-style="padding: 0;">
    <n-tabs
      type="line"
      size="large"
      :tabs-padding="20"
      pane-style="padding: 20px;"
      default-value="Limit"
      justify-content="space-evenly"
    >

      <n-tab-pane name="Limit" tab="Limit">
        <n-space vertical>
          <n-form>
            <n-form-item label="Price">
              <n-input-number v-model="value" :precision="8" placeholder="Price" :min="0" :step="0.00000001" />
            </n-form-item>
            <n-form-item label="Amount">
              <n-input-number v-model="value" :precision="8" placeholder="Amount" :min="0" :step="0.00000001" />
            </n-form-item>
            <n-form-item label="Total">
              <n-input-number v-model="value" :precision="8" placeholder="Total" :min="0" :step="0.00000001" />
            </n-form-item>
            <n-form-item>
              <n-button type="primary" @click="sendOrder">Buy</n-button>
              <n-divider vertical />
              <n-button type="error" ghost>Sell</n-button>
            </n-form-item>
          </n-form>
        </n-space>
      </n-tab-pane>

      <n-tab-pane name="Market" tab="Market">
        <n-space vertical>
          <n-form>
            <n-form-item label="Amount">
              <n-input-number v-model="value" :precision="8" placeholder="Amount" :min="0" :step="0.00000001" />
            </n-form-item>
            <n-form-item label="Total">
              <n-input-number v-model="value" :precision="8" placeholder="Total" :min="0" :step="0.00000001" />
            </n-form-item>
            <n-form-item>
              <n-button type="primary" @click="sendOrder">Buy</n-button>
              <n-divider vertical />
              <n-button type="error" ghost>Sell</n-button>
            </n-form-item>
          </n-form>
        </n-space>
      </n-tab-pane>

    </n-tabs>
  </n-card>
  </div>
</template>

<style scoped lang="sass">
.n-card
  max-width: 320px
  width: 320px
.n-input-number
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between

.n-button
  flex-grow: 1
</style>


<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: ['sendOrder'],
  setup () {
    return {
      value: ref(null)
    }
  }
})
</script>
