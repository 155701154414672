<template>
  <div>
    <n-tabs
      :value="activeTab"
      type="line"
      tab-style="min-width: 80px;"
      size="large"
      class="tabs"
      @update:value="handleTabClick"
    >
      <n-tab-pane v-for="pair in store.pairs" :key="pair" :tab="pair" :name="pair">
      </n-tab-pane>
    </n-tabs>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, reactive } from 'vue'
import { useMainStore } from '../../stores/main'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const store = reactive(useMainStore())
const activeTab = ref(route.query.active || store.pairs[0])


const handleTabClick = (id: any) => {
  setQueryValue('active', id)
  activeTab.value = id
}

const setQueryValue = (queryName: any, newValue: any) => {
  router.push({
    query: {
      ...route.query,
      [queryName]: newValue,
    }
  })
}
</script>

<style scoped lang="sass">
.tabs
  flex-direction: row
</style>
