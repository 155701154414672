<template>
  <div ref="tableRef" class="orders">
    <!-- :style="{marginTop: marginTop}" -->
    <div class="center" :style="{ top: calculatePositionOfCenter + 'px' }"></div>
    <n-space vertical class="orders-n-space">
      <n-table :bordered="false" :single-line="false" size="small">
        <!-- <thead>
          <tr>
            <th>Price</th>
            <th>Amount</th>
            <th>Total</th>
            <th>Sum</th>
            <th>Percent</th>
            <th>PercentSum</th>
          </tr>
        </thead> -->
        <tbody>
          <tr v-for="(item, index) in data.asks" :key="`${index}-asks`" :style="getGradientStyle({percent: item.percent, side: 'asks'})" class="order">
            <td style="background: none;">
              {{ item?.price && item?.price }}
              <div class="percentToSpread">{{item?.percentToSpread && item?.percentToSpread.toFixed(3)}}%</div>
            </td>
            <td style="background: none;">{{ item?.amount && item?.amount.toFixed(2) }}</td>
            <!-- <td style="background: none;">{{ item?.total ? item.total.toFixed(2) : '-' }}</td> -->
            <!-- <td style="background: none;">{{ item?.sum || "-" }}</td> -->
            <!-- <td style="background: none;">{{ item?.percent && item?.percent.toFixed(2) }}</td> -->
            <!-- <td style="background: none;">{{ item?.percentSum && item?.percentSum.toFixed(2) }}</td> -->
            <!-- <td style="background: none;">{{ item?.percentToSpread && item?.percentToSpread.toFixed(2) }}</td> -->
            <!-- <td style="background: none;">
              <div v-if="item.users">
                <AvatarGroup :options="item.users"/>
              </div>
            </td> -->
          </tr>
          <tr ref="ordersCenter"></tr>
          <!-- <tr>
            <td colspan="4" className="spreads">{{dataComputed.spread}} - {{dataComputed.spreadPercent}}%</td>
          </tr> -->
          <tr v-for="(item, index) in data?.bids" :key="`${index}-bids`" :style="getGradientStyle({percent: item.percentSum, side: 'bids'})" class="order">
            <td style="background: none;">
              {{ item?.price }}
              <div class="percentToSpread">{{item?.percentToSpread && item?.percentToSpread.toFixed(2)}}</div>
            </td>
            <td style="background: none;">{{ item?.amount }}</td>
            <!-- <td style="background: none;">{{ item?.total ? item.total.toFixed(2) : '-' }}</td> -->
            <!-- <td style="background: none;">{{ item?.sum }}</td> -->
            <!-- <td style="background: none;">{{ item?.percent && item?.percent.toFixed(2) }}</td> -->
            <!-- <td style="background: none;">{{ item?.percentSum && item?.percentSum.toFixed(2) }}</td> -->
            <!-- <td style="background: none;">{{ item?.percentToSpread && item?.percentToSpread.toFixed(2) }}</td> -->
            <!-- <td style="background: none;">
              <div v-if="item.users">
                <AvatarGroup :options="item.users"/>
              </div>
            </td> -->
          </tr>
        </tbody>
      </n-table>
    </n-space>
  </div>
</template>

<script lang="ts" setup>
import { ref, toRefs, computed, onMounted, watch, nextTick } from 'vue'
import { defineProps } from 'vue'
import { useMainStore } from '@/stores/main'
import _ from 'lodash'
const mainStore = useMainStore()
// import Side from './Side.vue'
// import Identicon from '../Identicon.vue'
// import AvatarGroup from '../AvatarGroup/AvatarGroup.vue'
import { divide } from 'lodash';

const store = useMainStore();

const props: any = defineProps({
  data: Object as any,
  widgetRef: Object as any,
  exchangeId: String,
  baseId: String,
  quoteId: String,
})
const { exchangeId, baseId, quoteId } = toRefs(props);

const ordersCenter: any = ref(null)
const tableRef: any = ref(null)
const dataLength: any = computed(() => {
  return props.data.length
})
const marginTop = ref(0)

const toCenter = () => {
  // console.log('toCenter');
  // console.log({tableRef: tableRef.value});
  // console.log({ordersCenter: ordersCenter.value});


  const widget = tableRef.value // props?.widgetRef;
  // console.log({widget});
  if (!widget) return;

  const widgetHeight = widget.offsetHeight;
  // console.log({widgetHeight});

  const top = ordersCenter.value.offsetTop;
  // console.log({top});

  const scrollTo = top - widgetHeight / 2;
  // console.log({scrollTo})

  widget.scrollTop = top - widgetHeight / 2;
}

onMounted(() => {
  nextTick(() => {
    toCenter()
  })
})

const getGradientStyle = ({percent, side}: {percent: number, side: string}) => {
  const isCenter = false;
  var leftPercent;
  var rightPercent;
  if (isCenter) {
    leftPercent = (100-percent)/2;
    rightPercent = 100-leftPercent;
  } else {
    leftPercent = 0;
    rightPercent = percent;
  }

  const green = 'rgb(38 57 53)' // '#7fe7c4';
  const red = '#e88080';

  const color = side === 'asks' ? red : green;
  // const color = 'rgb(242, 201, 125, 0.5)'
  // const color = 'rgb(38 57 53)' // color for big volumes
  return {
    background: `
      linear-gradient(
        90deg, rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) ${leftPercent}%,
        ${color} ${leftPercent}%,
        ${color} ${rightPercent}%,
        rgba(255, 255, 255, 0) ${rightPercent}%,
        rgba(255, 255, 255, 0) 100%
    )`,
  };
};

const dataComputed = computed(() => {
  const data = _.cloneDeep(props.data) || {};
  return data;
})

// Нужно найти количество элементов до цены, которая равна spreadPrice
// Нужно учитывать, что цены могут быть пропуски в ордербуке, т.е. цена не линейная (не всегда будут отрисовывать пустые ордера в ордербуке)
const calculatePositionOfCenter = computed(() => {
  if (store.scalpers[`${baseId.value}/${quoteId.value}/${exchangeId.value}`]) {
    const spreadPrice = store.scalpers[`${baseId.value}/${quoteId.value}/${exchangeId.value}`].spreadPrice;
  }
  const rowHeight = store.rowHeight;
  const orderbook = dataComputed?.value;
  const asksN = orderbook?.asks?.length;
  if (!asksN) return 0;
  const marginTop = asksN * rowHeight
  const id = `${baseId.value}/${quoteId.value}/${exchangeId.value}`;
  if (!store.scalpers[id]) store.scalpers[id] = {};
  store.scalpers[id].orderBooksCenter = marginTop;
  return marginTop;
})

// watch(() => props.data, () => {
//   toCenter();
// }, { deep: true });
</script>

<style scoped lang="sass">
.orders
  min-height: 100% // TODO: need 100%
  // height: 100%
  width: 100%
  display: flex
.orders-n-space
  width: 100%
.spreads
  text-align: center
td
  border-right: none
.center
  top: 100px
  position: absolute
  z-index: 10000
  background: #70c0e8
  height: 2px
  width: 100%

.percentToSpread
  display: none
  position: absolute
  z-index: 1005
  width: 100%
  height: 100%
  top: 0
  rigth: 0
  text-align: right
  padding-right: 10px
  vertical-align: middle
  align-items: center
  justify-content: flex-end
.order
  position: relative
  background: orange
  &:hover .percentToSpread
    display: flex
    background: rgb(0 0 255 / 9%)
</style>
