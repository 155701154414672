<template>
  <div class="page" ref="terminalRef">
    <div class="header">
      <Navbar :address="addressRef" :balance="balanceRef" :login="login" :logout="logout" />
    </div>
    <div class="dashboard">
      <div class="dashboard-header">
        <!-- <h1>{{dashboardBodyHeight}}</h1> -->
        <!-- <h1>Dashboard {{activePair}} {{baseId}} {{quoteId}} {{activeExchanges}}</h1> -->
        <!-- <h2>{{instrumentsRef.map(instrument => instrument.exchangeId)}}</h2> -->
      </div>
      <div class="dashboard-body" ref="dashboardBody" :style="{ width: `calc(100vw * ${activeExchanges.length} * ${paneWidth})` }">
        <splitpanes :style="{ height: dashboardBodyHeight }" class="default-theme" :height="dashboardBodyHeight">
          <pane v-for="exchangeId in activeExchanges" :key="exchangeId">
            <Widget :exchangeId="exchangeId" :baseId="baseId" :quoteId="quoteId" />
          </pane>
        </splitpanes>
      </div>
      <div class="dashboard-footer">
        <TimeSlider />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import Navbar from '@/components/Navbar/Navbar.vue'
import TimeSlider from '@/components/TimeSlider/TimeSlider.vue'
import { useMainStore } from '@/stores/main'
import { useRoute, useRouter } from 'vue-router'
import Widget from '../Widget/Widget.vue'
import Web3 from 'web3';
import Web3Token from 'web3-token';
import axios from 'axios';

const terminalRef = ref();

const route = useRoute()
const router = useRouter()

const paneWidth = computed(() => parseFloat(route.query.paneWidth as string) || "0.8")

const activePair = computed(() => route.query.active)
const activePairs = computed(() => route.query.pairs || 'BTC/USDT,ETH/USDT')
const activeExchanges = computed(() => (route.query.exchanges && (route.query.exchanges as string).split(',')) || ['binance', 'kucoin'])

const baseId = computed(() => (activePair.value as string).split('/')[0])
const quoteId = computed(() => (activePair.value as string).split('/')[1])

const store = useMainStore()
const demoData = ref('')
const addressRef = ref('')
const tokenRef = ref('')
const balanceRef = ref(0)
const instrumentsRef = ref([]);



const dashboardBody = ref()
let dashboardBodyHeight = ref('0px');

const updateDashboardBodyHeight = () => {
  dashboardBodyHeight.value = dashboardBody.value ? `${dashboardBody.value.offsetHeight - 15}px` : '0px';
};

// TODO: debounce или переделать на хук
onUnmounted(() => {
  window.removeEventListener('resize', updateDashboardBodyHeight);

  terminalRef.value.removeEventListener('wheel', handleWheel);
  window.removeEventListener('keydown', handleKeydown);
});

const login = async () => {
  const web3 = new Web3((window as any).ethereum);
  const address = (await web3.eth.getAccounts())[0];
  addressRef.value = address;
  const token = await Web3Token.sign((msg: string) => web3.eth.personal.sign(msg, address, 'marketmaker'), '7d');
  tokenRef.value = token;
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // save token to sessionStorage
  sessionStorage.setItem('address', address);
  sessionStorage.setItem('token', token);
  getBalance();
}

const getBalance = async () => {
  axios.get(`${import.meta.env.VITE_API_URL}/balance`, { // TODO: change to real endpoint
    headers: {
      Authorization: `${tokenRef.value}`
    }
  })
  .then((response) => {
    // console.log('BALANCE RESPONSE: ', response);
    // console.log(response.data);
    balanceRef.value = response.data?.balance;
    sessionStorage.setItem('balance', response.data?.balance);
  })
  .catch((error) => {
    console.error(error);
  });
}

const logout = () => {
  sessionStorage.removeItem('address');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('balance');
  addressRef.value = '';
  tokenRef.value = '';
  balanceRef.value = 0;
}

onMounted(async () => {
  terminalRef.value.addEventListener('wheel', handleWheel);
  window.addEventListener('keydown', handleKeydown);

  const width = store.exchanges.length === 1 ? 1 : 0.8;
  if (!route.query.paneWidth) {
    router.push({ query: { ...route.query, paneWidth: width } });
  }

  updateDashboardBodyHeight();
  window.addEventListener('resize', updateDashboardBodyHeight);

  sessionStorage.getItem('address') && (addressRef.value = sessionStorage.getItem('address') as string);
  sessionStorage.getItem('token') && (tokenRef.value = sessionStorage.getItem('token') as string);
  getBalance();
  sessionStorage.getItem('balance') && (balanceRef.value = Number(sessionStorage.getItem('balance') as string));

  const instruments = await store.fetchAllInstrumentsByPair('ETH/BTC');
  console.log('instruments', {instruments})
  instrumentsRef.value = instruments;
  // console.log('mounted!')
  // // demoData.value = await store.fetchOrderbooksRest()

  // // Connection to MetaMask wallet
  // const web3 = new Web3((window as any).ethereum);

  // const addresses = await (window as any).ethereum.request({ method: 'eth_requestAccounts'});
  // console.log('addresses!', addresses);

  // // // getting address from which we will sign message
  // const address = (await web3.eth.getAccounts())[0];
  // // const address = addresses[0];
  // console.log('address', address);
  // addressRef.value = address;


  // // // generating a token with 1 day of expiration time
  // const token = await Web3Token.sign((msg: string) => web3.eth.personal.sign(msg, address, 'marketmaker'), '1d');
  // // const token = (window as any).ethereum.sign('1d');
  // console.log('token', token);
  // tokenRef.value = token;
  // // attaching token to authorization header ... for example
  // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

})

const handleWheel = (e: WheelEvent) => {
  if (e.ctrlKey) {
    e.preventDefault();
    adjustPriceStep(e.deltaY >= 0 ? -1 : 1);
  }
};

const handleKeydown = (e: KeyboardEvent) => {
  console.log('handleKeydown', e)
  if (!['ArrowUp', 'ArrowDown'].includes(e.key)) return;

  e.preventDefault();

  const baseIncrement = e.key === 'ArrowUp' ? 1 : -1;
  let increment = 0;

  if (e.shiftKey) increment = baseIncrement * 10;
  else if (e.altKey) increment = baseIncrement * 0.1;
  else if (e.metaKey) increment = baseIncrement;
  else if (e.ctrlKey) increment = baseIncrement;

  if (increment === 0) return;
  adjustPriceStep(increment);
};

const adjustPriceStep = (increment: number) => {
  console.log('adjustPriceStep', increment, store.priceStep, store.minPriceStep)
  let newPriceStep = store.priceStep + increment * store.minPriceStep;
  newPriceStep = Math.max(newPriceStep, store.minPriceStep);
  newPriceStep = parseFloat(newPriceStep.toFixed(2));
  store.priceStep = newPriceStep;
  router.push({ query: { ...route.query, priceStep: newPriceStep } });
};

// onMounted(() => {

// });

// onUnmounted(() => {

// });
</script>

<style scoped lang="sass">
.page
  display: flex
  flex-direction: column
  height: 100vh
.dashboard
  // margin: 15px 30px
  display: flex
  flex-direction: column
  flex: 1 0 auto
  overflow-y: auto
.dashboard-body
  flex: 1 0 auto
.splitpanes__pane
  display: flex
  justify-content: center
  align-items: center
  position: relative
  background: rgb(24, 24, 28) !important
  // box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset
  // background: #d2f2f2

// .splitpanes
//   background-color: #f8f8f8

// .splitpanes__splitter
//   background-color: #ccc
//   position: relative
//   &:before
//     content: ''
//     position: absolute
//     left: 0
//     top: 0
//     transition: opacity 0.4s
//     background-color: rgba(255, 0, 0, 0.3)
//     opacity: 0
//     z-index: 1
//   &:hover:before
//     opacity: 1

// .splitpanes--vertical > .splitpanes__splitter:before
//   left: -30px
//   right: -30px
//   height: 100%

// .splitpanes--horizontal > .splitpanes__splitter:before
//   top: -30px
//   bottom: -30px
//   width: 100%
</style>
