<template>
  <div class="clusters" ref="clustersRef" :style="`margin-top: ${commonMargin}px`">
    <div class="temp">{{temp}}</div>
    <n-space vertical v-for="candle in dataComputed" :key="candle.timestamp" class="cluster-candle" :style="`margin-top: ${candle.margin}px`">
      <n-table :bordered="false" :single-line="false" size="small">
        <tbody>
          <tr v-for="(item, index) in candle.clusterPoints" :key="`${index}-asks`">
            <td>
              <div class="gradient-background" :style="getGradientStyle(item)">
                {{ item.price && item.price.toFixed(2) || item.volume }}
              </div>
            </td>
          </tr>
        </tbody>
      </n-table>
    </n-space>
    <div class="center" :style="{ top: calculatePositionOfCenter + 'px' }"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, computed, onMounted, onUnmounted } from 'vue'
import { defineProps } from 'vue'
import _ from 'lodash';
import { useMainStore } from '@/stores/main';
// import { ClusterPoint, Candle } from '@/types.d';

const props = defineProps({
  baseId: String,
  quoteId: String,
  exchangeId: String,
  data: Object,
})

const { exchangeId, baseId, quoteId } = toRefs(props);

const commonMargin = computed(() => {
  if (!baseId?.value || !quoteId?.value || !exchangeId?.value) return 0;
  const id = `${baseId.value}/${quoteId.value}/${exchangeId.value}`;
  const clustersCenter = store?.scalpers?.[id]?.clustersCenter || 0;
  const orderBooksCenter = store?.scalpers?.[id]?.orderBooksCenter || 0;
  return Math.max(orderBooksCenter, clustersCenter) - clustersCenter;
});

// spread price
// TODO:
// const spreadPrice = ref(42663.32); // 42663.325 пока округлили в меньшую сторону
// const minPriceStep = ref(0.01);
// const priceStep = ref(0.01);
// get from useMainStore
const store = useMainStore();
// const spreadPrice = computed(() => mainStore.spreadPrice);
// const minPriceStep = computed(() => mainStore.minPriceStep);
// const priceStep = computed(() => mainStore.priceStep);
const clustersRef: any = ref(null);
const temp = ref();

// const handleWheel = (e: any) => {
//   console.log('HANDLE WHEEL')
//   if (e.ctrlKey) {
//     e.preventDefault();
//     const delta = e.deltaY;
//     if(delta >= 0){
//       let newPriceStep = store.priceStep - store.minPriceStep;
//       newPriceStep = newPriceStep < store.minPriceStep ? store.minPriceStep : newPriceStep;
//       newPriceStep = parseFloat(newPriceStep.toFixed(2));
//       // console.log({delta, newPriceStep});
//       store.priceStep = newPriceStep;
//     } else {
//       let newPriceStep = store.priceStep + store.minPriceStep;
//       newPriceStep = parseFloat(newPriceStep.toFixed(2));
//       // console.log({delta, newPriceStep});
//       store.priceStep = newPriceStep;
//     }
//   }
// };

// onMounted(() => {
//   clustersRef.value.addEventListener('wheel', handleWheel);
// });

// onUnmounted(() => {
//   clustersRef.value.removeEventListener('wheel', handleWheel);
// });


const calculateMargin = (candle: any, isCenter: boolean = false): any => {
  // console.log({value: clustersRef.value});
  if (!clustersRef.value) return 0;
  const widgetHeight = clustersRef.value.parentNode.offsetHeight;
  const centerHeight = isCenter ? widgetHeight/2 : 0;
  // console.log({widgetHeight, centerHeight});
  const pointPrice = candle?.clusterPoints?.[0]?.price;
  // console.log({pointPrice});
  const id = `${props.baseId}/${props.quoteId}/${props.exchangeId}`;
  const spreadPrice = store?.scalpers?.[id]?.spreadPrice || 0;
  // console.log({spreadPrice});
  const priceDiff = pointPrice - spreadPrice;
  // console.log({priceDiff});
  const stepsDiff = priceDiff / store.priceStep;
  // console.log({stepsDiff});
  const rowHeight = store.rowHeight;
  const margin = (-stepsDiff * rowHeight) + centerHeight + commonMargin.value; // вынести commonMargin в input params
  return { margin, pointPrice, priceDiff, stepsDiff, rowHeight, widgetHeight, centerHeight };
};

const dataComputed = computed(() => {
  const data = _.cloneDeep(props.data) || [];
  for (const candle of data as any) {
    const clusterPoints = candle.clusterPoints;
    const groupedClusterPoints = [];
    let currentGroup = null;

    for (const point of clusterPoints) {
      const priceGroup = Math.ceil(point.price / store.priceStep) * store.priceStep;

      // If the price group is different from the current group price, create a new group
      if (currentGroup && currentGroup.price !== priceGroup) {
        groupedClusterPoints.push(currentGroup);
        currentGroup = null;
      }

      // If there is no current group, create a new one
      if (!currentGroup) {
        currentGroup = {
          price: priceGroup,
          priceUp: priceGroup, // This is the start of the new group
          priceDown: priceGroup + store.priceStep, // This is the end of the new group
          volume: point.volume,
          percent: point.percent,
          empty: false,
        };
      } else {
        // If the price falls within the current group range, add the volume and percent to the current group
        currentGroup.volume += point.volume;
        currentGroup.percent += point.percent;
      }
    }

    if (currentGroup) {
      groupedClusterPoints.push(currentGroup);
    }

    candle.clusterPoints = groupedClusterPoints;
    const { margin, pointPrice, priceDiff, stepsDiff, rowHeight, widgetHeight, centerHeight } = calculateMargin(candle, false);
    candle.margin = margin;
    candle.pointPrice = pointPrice;
    candle.priceDiff = priceDiff;
    candle.stepsDiff = stepsDiff;
    candle.rowHeight = rowHeight;
    candle.widgetHeight = widgetHeight;
    candle.centerHeight = centerHeight;
  }
  // console.log({dataComputed: data})
  return data;
});

const getGradientStyle = (item: any) => {
  const percent = item.percent;
  const empty = item.empty;
  const isCenter = false;
  var leftPercent;
  var rightPercent;
  if (isCenter) {
    leftPercent = (100-percent)/2;
    rightPercent = 100-leftPercent;
  } else {
    leftPercent = 0;
    rightPercent = percent;
  }

  // const color = 'rgb(242, 201, 125, 0.5)'
  const color = 'rgb(38 57 53)' // color for big volumes
  return {
    background: `
      linear-gradient(
        90deg, rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) ${leftPercent}%,
        ${color} ${leftPercent}%,
        ${color} ${rightPercent}%,
        rgba(255, 255, 255, 0) ${rightPercent}%,
        rgba(255, 255, 255, 0) 100%
    )`,
    color: empty ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.82)',
  };
};

// У меня есть spreadPrice, который я получаю из store
// У меня есть первая цена в clusterPoints
// Нужно найти количество элементов до цены, которая равна spreadPrice
// У меня есть rowHeight
// return asksN * rowHeight
// В этой функции мне надо нарисовать линию где spreadPrice из orderbook
const calculatePositionOfCenter = computed(() => {
  console.log('calculatePositionOfCenter');
  if (!dataComputed.value.length) { return 0 };
  if (!store.scalpers) { return 0 };
  if (!baseId?.value || !quoteId?.value || !exchangeId?.value) { return 0 };
  const id = `${baseId.value}/${quoteId.value}/${exchangeId.value}`;
  const spreadPrice = store.scalpers?.[id]?.spreadPrice || 0;
  const rowHeight = store?.rowHeight;
  const priceStep = store?.priceStep;
  const candle: any = _.cloneDeep(dataComputed?.value);
  const widgetHeight = clustersRef?.value?.parentNode?.offsetHeight;
  const isCenter = false;
  const centerHeight = isCenter ? widgetHeight/2 : 0;
  console.log({id, spreadPrice, rowHeight, candle});

  // Найти среди все clusterPoints тот, где первая цена максимальная
  const maxPricesInClusterPoints = candle.map((candle: any) => candle.clusterPoints?.[0].price);
  console.log({maxPricesInClusterPoints});
  const maxPrice = Math.max(...maxPricesInClusterPoints);
  console.log({maxPrice});
  const diffPrice = maxPrice - spreadPrice;
  console.log({diffPrice});
  const steps = diffPrice / priceStep;
  console.log({steps});
  const marginTop = steps * rowHeight;
  temp.value = JSON.stringify({priceStep, spreadPrice, maxPrice, diffPrice, steps, marginTop}, null, 2)
  console.log({marginTop});
  if (store.scalpers?.[id]) store.scalpers[id].clustersCenter = marginTop; // TODO: action
  return marginTop + centerHeight // marginTop;
})
</script>

<style scoped lang="sass">
.clusters
  display: flex
  position: relative
  height: 100%
  overflow-y: auto
.cluster-candle
  // position: absolute
  // margin-top: 130px // TODO: не помню зачем этот костыль
  left: 10px
.gradient-background
  white-space: nowrap
  width: 200px
  min-width: 200px
  max-width: 200px
  /* Add a height property here based on your design */
.temp
  position: absolute
  bottom: 30px
  left: 30px
  width: 300px
.center
  top: 100px
  position: absolute
  background: pink
  height: 2px
  width: 100%
</style>
