import './assets/base.css'

import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import naive from 'naive-ui'

import App from './App.vue'
import * as Sentry from "@sentry/vue";

import type { Router } from 'vue-router';
import router from './router';

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

const app = createApp(App)
app.component('Splitpanes', Splitpanes)
app.component('Pane', Pane)

// Sentry.init({
//   app,
//   dsn: "https://2167cca9793ecbd4a256bbf676e14eaf@o4507011386310656.ingest.us.sentry.io/4507011548971008",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});
// (window as any).store = store;

app.use(pinia)
app.use(router)
app.use(naive)

app.mount('#app')
