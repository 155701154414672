
<template>
  <CreateOrderUi :sendOrder="() => {
    console.log('send order');
    console.log('send order again');
    // handleStart();
    // handleFinish(); // handleError();
    handleError();
  }"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useLoadingBar } from 'naive-ui'
import CreateOrderUi from './CreateOrderUi.vue'

const loadingBar = useLoadingBar()
const disabled = ref(true)

const handleStart = () => {
  loadingBar.start()
  disabled.value = false
}

const handleFinish = () => {
  loadingBar.finish()
  disabled.value = true
}

const handleError = () => {
  disabled.value = true
  loadingBar.error()
}
</script>
