<template>
  <div class="trades">
    <n-table>
      <tbody>
        <tr v-for="(item, index) in data" :key="item.id">
          <td>
            <b>{{ item.id }}</b>
          </td>
          <td>
            {{ item.price }} * {{ item.amount }} = {{ item.price * item.amount }}
          </td>
          <td align="right">
            {{ formatTime(item.timestamp) }}
          </td>
        </tr>
      </tbody>
    </n-table>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { defineProps } from 'vue'
import moment from 'moment'

const props = defineProps({
  data: Object,
})

const formatTime = (value: number) => {
  return moment(value).format('DD:MM:YY hh:mm:ss');
}
</script>

<style scoped lang="sass">
</style>
