export default [
  {
    id: 'BTC/USDT/binance/tick',
    exchangeId: 'binance',
    instrumentId: 'BTC/USDT/binance',
    pairId: 'BTC/USDT',
    baseId: 'BTC',
    quoteId: 'USDT',
    timestamp: 1703599613250,
    timestampStart: 1703599613250,
    timestampEnd: 1703599614961,
    timeframe: 0,
    timeframeId: 'tick',
    timeframeName: 'tick',
    status: 'closed',
    open: 42663.34,
    high: 42663.34,
    low: 42663.33,
    close: 42663.33,
    xClose: 42663.335,
    xOpen: 42663.12795593262,
    xHigh: 42663.34,
    xLow: 42663.12795593262,
    count: 10,
    buyCount: 6,
    sellCount: 4,
    buyVolume: 0.02614,
    sellVolume: 0.44192,
    volume: 0.46806000000000003,
    bestAsk: 42663.33,
    bestBid: 42663.34,
    spreadPrice: 42663.335,
    change: -0.00999999999476131,
    changePercent: -0.000023439327522789618,
    changePercentAbs: 0.000023439327522789618,
    countDisbalance: 2,
    countDisbalancePercent: 20,
    countDisbalancePercentAbs: 20,
    volumeDisbalance: -0.41578,
    volumeDisbalancePercent: -88.83049181728838,
    volumeDisbalancePercentAbs: 88.83049181728838,
    weightedAverageBuyPrice: 0.004356666666666667,
    weightedAverageSellPrice: 0.11048,
    weightedAveragePrice: 0.046806,
    medianBuyPrice: 42663.34,
    medianSellPrice: 42663.33,
    medianPrice: 42663.34,
    priceStandardDeviation: 42663.28919400028
  },
  {
    id: 'BTC/USDT/binance/tick',
    exchangeId: 'binance',
    instrumentId: 'BTC/USDT/binance',
    pairId: 'BTC/USDT',
    baseId: 'BTC',
    quoteId: 'USDT',
    timestamp: 1703599612055,
    timestampStart: 1703599612055,
    timestampEnd: 1703599613250,
    timeframe: 0,
    timeframeId: 'tick',
    timeframeName: 'tick',
    status: 'closed',
    open: 42663.33,
    high: 42663.34,
    low: 42663.33,
    close: 42663.34,
    xClose: 42663.335,
    xOpen: 42662.920911865236,
    xHigh: 42663.34,
    xLow: 42662.920911865236,
    count: 10,
    buyCount: 6,
    sellCount: 4,
    buyVolume: 0.21359,
    sellVolume: 0.00904,
    volume: 0.22263,
    bestAsk: 42663.33,
    bestBid: 42663.34,
    spreadPrice: 42663.335,
    change: 0.00999999999476131,
    changePercent: 0.00002343933301681165,
    changePercentAbs: 0.00002343933301681165,
    countDisbalance: 2,
    countDisbalancePercent: 20,
    countDisbalancePercentAbs: 20,
    volumeDisbalance: 0.20455,
    volumeDisbalancePercent: 91.87890221443652,
    volumeDisbalancePercentAbs: 91.87890221443652,
    weightedAverageBuyPrice: 0.035598333333333336,
    weightedAverageSellPrice: 0.00226,
    weightedAveragePrice: 0.022262999999999998,
    medianBuyPrice: 42663.34,
    medianSellPrice: 42663.33,
    medianPrice: 42663.34,
    priceStandardDeviation: 42663.31373700028
  },
  {
    id: 'BTC/USDT/binance/tick',
    exchangeId: 'binance',
    instrumentId: 'BTC/USDT/binance',
    pairId: 'BTC/USDT',
    baseId: 'BTC',
    quoteId: 'USDT',
    timestamp: 1703599611270,
    timestampStart: 1703599611270,
    timestampEnd: 1703599611669,
    timeframe: 0,
    timeframeId: 'tick',
    timeframeName: 'tick',
    status: 'closed',
    open: 42663.32,
    high: 42663.34,
    low: 42663.32,
    close: 42663.34,
    xClose: 42663.33,
    xOpen: 42662.51182373047,
    xHigh: 42663.34,
    xLow: 42662.51182373047,
    count: 10,
    buyCount: 8,
    sellCount: 2,
    buyVolume: 0.42406,
    sellVolume: 0.00482,
    volume: 0.42887999999999993,
    bestAsk: 42663.32,
    bestBid: 42663.34,
    spreadPrice: 42663.33,
    change: 0.01999999999679858,
    changePercent: 0.000046878677038726895,
    changePercentAbs: 0.000046878677038726895,
    countDisbalance: 6,
    countDisbalancePercent: 60,
    countDisbalancePercentAbs: 60,
    volumeDisbalance: 0.41924,
    volumeDisbalancePercent: 97.75228502145123,
    volumeDisbalancePercentAbs: 97.75228502145123,
    weightedAverageBuyPrice: 0.0530075,
    weightedAverageSellPrice: 0.00241,
    weightedAveragePrice: 0.042887999999999996,
    medianBuyPrice: 42663.33,
    medianSellPrice: 42663.32,
    medianPrice: 42663.33,
    priceStandardDeviation: 42663.287112000464
  },
  {
    id: 'BTC/USDT/binance/tick',
    exchangeId: 'binance',
    instrumentId: 'BTC/USDT/binance',
    pairId: 'BTC/USDT',
    baseId: 'BTC',
    quoteId: 'USDT',
    timestamp: 1703599610867,
    timestampStart: 1703599610867,
    timestampEnd: 1703599611072,
    timeframe: 0,
    timeframeId: 'tick',
    timeframeName: 'tick',
    status: 'closed',
    open: 42663.11,
    high: 42663.33,
    low: 42663.11,
    close: 42663.32,
    xClose: 42663.2175,
    xOpen: 42661.80614746094,
    xHigh: 42663.33,
    xLow: 42661.80614746094,
    count: 10,
    buyCount: 4,
    sellCount: 6,
    buyVolume: 0.016200000000000003,
    sellVolume: 0.050339999999999996,
    volume: 0.06654,
    bestAsk: 42663.11,
    bestBid: 42663.33,
    spreadPrice: 42663.22,
    change: 0.20999999999912689,
    changePercent: 0.0004922285318607267,
    changePercentAbs: 0.0004922285318607267,
    countDisbalance: -2,
    countDisbalancePercent: -20,
    countDisbalancePercentAbs: 20,
    volumeDisbalance: -0.03413999999999999,
    volumeDisbalancePercent: -51.30748422001802,
    volumeDisbalancePercentAbs: 51.30748422001802,
    weightedAverageBuyPrice: 0.004050000000000001,
    weightedAverageSellPrice: 0.00839,
    weightedAveragePrice: 0.006654,
    medianBuyPrice: 42663.2,
    medianSellPrice: 42663.32,
    medianPrice: 42663.32,
    priceStandardDeviation: 42663.24934608443
  },
  {
    id: 'BTC/USDT/binance/tick',
    exchangeId: 'binance',
    instrumentId: 'BTC/USDT/binance',
    pairId: 'BTC/USDT',
    baseId: 'BTC',
    quoteId: 'USDT',
    timestamp: 1703599610815,
    timestampStart: 1703599610815,
    timestampEnd: 1703599610867,
    timeframe: 0,
    timeframeId: 'tick',
    timeframeName: 'tick',
    status: 'closed',
    open: 42661.64,
    high: 42663.11,
    low: 42661.64,
    close: 42663.11,
    xClose: 42662.375,
    xOpen: 42661.23729492188,
    xHigh: 42663.11,
    xLow: 42661.23729492188,
    count: 10,
    buyCount: 8,
    sellCount: 2,
    buyVolume: 0.00485,
    sellVolume: 0.12179,
    volume: 0.12663999999999997,
    bestAsk: 42661.64,
    bestBid: 42663.11,
    spreadPrice: 42662.375,
    change: 1.4700000000011642,
    changePercent: 0.003445718448707467,
    changePercentAbs: 0.003445718448707467,
    countDisbalance: 6,
    countDisbalancePercent: 60,
    countDisbalancePercentAbs: 60,
    volumeDisbalance: -0.11693999999999999,
    volumeDisbalancePercent: -92.34049273531271,
    volumeDisbalancePercentAbs: 92.34049273531271,
    weightedAverageBuyPrice: 0.00060625,
    weightedAverageSellPrice: 0.060895,
    weightedAveragePrice: 0.012663999999999998,
    medianBuyPrice: 42662.42,
    medianSellPrice: 42663.11,
    medianPrice: 42662.42,
    priceStandardDeviation: 42662.31233879831
  },
  {
    id: 'BTC/USDT/binance/tick',
    exchangeId: 'binance',
    instrumentId: 'BTC/USDT/binance',
    pairId: 'BTC/USDT',
    baseId: 'BTC',
    quoteId: 'USDT',
    timestamp: 1703599610714,
    timestampStart: 1703599610714,
    timestampEnd: 1703599610815,
    timeframe: 0,
    timeframeId: 'tick',
    timeframeName: 'tick',
    status: 'closed',
    open: 42661.13,
    high: 42661.56,
    low: 42661.13,
    close: 42661.56,
    xClose: 42661.345,
    xOpen: 42661.12958984375,
    xHigh: 42661.56,
    xLow: 42661.12958984375,
    count: 10,
    buyCount: 10,
    sellCount: 0,
    buyVolume: 0.6258799999999999,
    sellVolume: 0,
    volume: 0.6258799999999999,
    bestAsk: 42661.13,
    bestBid: 42661.56,
    spreadPrice: 42661.345,
    change: 0.43000000000029104,
    changePercent: 0.0010079432963925033,
    changePercentAbs: 0.0010079432963925033,
    countDisbalance: 10,
    countDisbalancePercent: 100,
    countDisbalancePercentAbs: 100,
    volumeDisbalance: 0.6258799999999999,
    volumeDisbalancePercent: 100,
    volumeDisbalancePercentAbs: 100,
    weightedAverageBuyPrice: 0.06258799999999999,
    weightedAverageSellPrice: NaN,
    weightedAveragePrice: 0.06258799999999999,
    medianBuyPrice: 42661.16,
    medianSellPrice: undefined,
    medianPrice: 42661.16,
    priceStandardDeviation: 42661.22541243196
  }
]
