<template>
  <div class="orderbook-wrapper">
    <!-- {{temp}} -->
    <!-- {{store.priceStep}} -->
    <!-- {{store.demo}} -->
    <!-- {{dataComputed}} -->
    <!-- {{data}} -->
    <!-- {{store?.scalpers[`${baseId}/${quoteId}/${exchangeId}`].spreadPrice.toFixed(2)}} - {{(data as any)?.spread.toFixed(2)}} -->
    <!-- {{store.sliderValue}} -->
    <!-- {{store.historyEnabled}} -->
    <!-- {{Object.keys(data).length}} -->
    <OrderBookUi :data="dataComputed" :widgetRef="widgetRef" :baseId="baseId" :quoteId="quoteId" :exchangeId="exchangeId" />
    <!-- <hr /> -->
    <!-- <OrderBookUi :data="data" :widgetRef="widgetRef" /> -->
  </div>
</template>

<script lang="ts" setup>
import OrderBookUi from './OrderBookUi.vue';
import ToggleView from './ToggleView.vue'
import demoData from './data'
import { useMainStore } from '@/stores/main';
import { defineProps, toRefs, ref, onMounted, reactive, watch, computed, nextTick } from 'vue'
import type { Ref } from 'vue'
import type { OrderBook, Order, Dictionary } from '@/types.d';
import _ from 'lodash';

type OrderComputed = {
  [key: string]: any
};

const props: any = defineProps({
  widgetRef: Object as any,
  exchangeId: String,
  baseId: String,
  quoteId: String,
})
const store = useMainStore();
const data: Ref<OrderBook> = ref(store.demo ? demoData : {}); // demoData
const temp = ref();
const { exchangeId, baseId, quoteId } = toRefs(props);


// const store = reactive(useMainStore());

const fetchData = async () => {
  const fetchResult = await store.fetchOrderbooksRest({exchangeId: exchangeId?.value, baseId: baseId?.value, quoteId: quoteId?.value});
  data.value = fetchResult;
  store.setSpreadPrice(`${baseId.value}/${quoteId.value}/${exchangeId.value}`, fetchResult?.spread)
  // store.spreadPrice = fetchResult?.spread
}

const dataComputed = computed(() => {
  const priceStep = store.priceStep;
  if (priceStep === 0) return data?.value;

  const firstAsk = data?.value?.asks?.[0]?.price;
  const lastAsk = data?.value?.asks?.[data?.value?.asks?.length - 1]?.price;
  const firstBid = data?.value?.bids?.[0]?.price;
  const lastBid = data?.value?.bids?.[data?.value?.bids?.length - 1]?.price;

  const asksSteps: Dictionary<OrderComputed> = {};
  const asksStepsList = [];
  if (firstAsk !== undefined && lastAsk !== undefined) {
    for (let i = lastAsk; i <= firstAsk; i += priceStep) {
      const priceToFixed = i.toFixed(2);
      asksSteps[priceToFixed] = {
        price: priceToFixed,
        amount: 0,
        total: 0,
      };
      asksStepsList.push(priceToFixed);
    }
  }

  const bidsSteps: Dictionary<OrderComputed> = {};
  const bidsStepsList = [];
  if (firstBid !== undefined && lastBid !== undefined) {
    for (let i = lastBid; i <= firstBid; i += priceStep) {
      const priceToFixed = i.toFixed(2);
      bidsSteps[priceToFixed] = {
        price: priceToFixed,
        amount: 0,
        total: 0,
      };
      bidsStepsList.push(priceToFixed);
    }
  }

  if (data?.value?.asks === undefined || data?.value?.bids === undefined) return data?.value;
  for (const ask of data?.value?.asks) {
    // найти подходящий шаг
    const inStepPrice = asksStepsList.find((orderPrice) => parseFloat(orderPrice) <= ask.price && parseFloat(orderPrice) + priceStep > ask.price);
    if (inStepPrice && asksSteps[inStepPrice]) {
      asksSteps[inStepPrice].amount += ask.amount;
      asksSteps[inStepPrice].total += ask.amount * ask.price;
    }
  }

  for (const bid of data?.value?.bids) {
    // найти подходящий шаг
    const inStepPrice = bidsStepsList.find((orderPrice) => parseFloat(orderPrice) <= bid.price && parseFloat(orderPrice) + priceStep > bid.price);
    if (inStepPrice && bidsSteps[inStepPrice]) {
      bidsSteps[inStepPrice].amount += bid.amount;
      bidsSteps[inStepPrice].total += bid.total;
    }
  }

  let asks = Object.values(asksSteps).sort((a, b) => a.price - b.price);
  let bids = Object.values(bidsSteps).sort((a, b) => a.price - b.price);

  let bidsSum = 0;
  let asksSum = 0;

  bids = bids.map((bid) => {
    bidsSum += bid.amount;
    return {
      ...bid,
      type: 'bid',
      total: Number(bid.total).toFixed(8),
      sum: bidsSum, // replace with actual logic
      percentToSpread: data?.value?.spread && Math.abs( (bid.price - data?.value?.spread) / data?.value?.spread * 100),
    };
  });

  asks = asks.map((ask) => {
    asksSum += ask.amount;
    return {
      ...ask,
      type: 'ask',
      total: Number(ask.total).toFixed(8),
      sum: asksSum, // replace with actual logic
      percentToSpread: data?.value?.spread && Math.abs((ask.price - data?.value?.spread) / data?.value?.spread * 100),
    };
  });

  bids = bids.map((bid) => {
    bid.percent = (bid.amount / bidsSum) * 100;
    bid.percentSum = bid.sum / bidsSum * 100;
    return bid;
  });

  asks = asks.map((ask) => {
    ask.percent = (ask.amount / asksSum) * 100;
    ask.percentSum = ask.sum / asksSum * 100;
    return ask;
  });

  asks = asks.sort((a, b) => b.price - a.price);
  bids = bids.sort((a, b) => b.price - a.price);

  return {
    ...data?.value,
    asks,
    bids,
  };
});

onMounted(async () => {
  console.log('onMounted', store.demo)
  if (store.demo) {
    store.setSpreadPrice(`${baseId.value}/${quoteId.value}/${exchangeId.value}`, data?.value?.spread || 0)
  };
  if (store.demo) return;
  fetchData()
  setInterval(async () => {
    await fetchData()
  }, 1000)
})

watch(() => [store.sliderValue, store.historyEnabled], async ([newSliderValue, newHistoryEnabled], [oldSliderValue, oldHistoryEnabled]) => {
  // console.log('watch')
  if (newHistoryEnabled && newSliderValue !== oldSliderValue) {
    await fetchData()
  }
});

</script>

<style scoped lang="sass">
.orderbook-wrapper
  position: relative
</style>

