<template>
  <div class="time-slider">
    <n-slider
      :class="{ hide: !store.historyEnabled }"
      :defaultVale="store.sliderValue"
      :value="store.sliderValue"
      @update:value="(value: number) => store.setSliderValue(value, formatTooltip({timeInSeconds: value as number, lodash: true}))"
      :step="1"
      :format-tooltip="(value: number) => formatTooltip({timeInSeconds: value, lodash: false})"
      :min="store.minSliderValue"
      :max="store.maxSliderValue"
      :disabled="!store.historyEnabled"
    />
    <div class="buttons-wrap">
      <n-button type="primary" v-if="store.historyEnabled" @click="store.toggleHistoryEnabled">{{formatTooltip({timeInSeconds: store.sliderValue as number})}}</n-button>
      <n-button type="primary" v-else @click="store.toggleHistoryEnabled">Live</n-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { useMainStore } from '@/stores/main'

const store = reactive(useMainStore());
const { setSliderValue } = store;
// А так оно не отслеживало изменения из store:
// const { maxSliderValue, minSliderValue, sliderValue, historyEnabled, toggleHistoryEnabled} = useMainStore();

// const decrementSliderValue = () => {
//   console.log('decrementSliderValue');
//   const value = store.sliderValue - 1;
//   store.setSliderValue(value, formatTooltip({timeInSeconds: value as number, lodash: true}));
// };

// const incrementSliderValue = () => {
//   console.log('incrementSliderValue');
//   const value = store.sliderValue + 1;
//   store.setSliderValue(value, formatTooltip({timeInSeconds: value as number, lodash: true}));
// };

const formatTooltip = ({timeInSeconds, lodash = false}: {
  timeInSeconds: number,
  lodash?: boolean
}) => {
  const date = new Date(timeInSeconds * 1000);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return lodash ? `${day}.${month}.${year}_${hours}:${minutes}:${seconds}` : `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};
</script>

<style scoped lang="sass">
.hide
  display: none
.time-slider
  position: relative
.buttons-wrap
  position: absolute
  right: 0px
  bottom: 18px
</style>
