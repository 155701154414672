<template>
  <div class="navbar">
    <div class="left-navbar">
      <div class="logo"><h1>MM</h1></div>
      <Tabs />
    </div>
    <Account :address="address" :balance="balance" :login="props.login" :logout="props.logout"/>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch, nextTick, toRefs } from 'vue'
import Tabs from '@/components/Navbar/Tabs.vue'
import Account from '@/components/Navbar/Account.vue'

const props = defineProps({
  address: String,
  balance: Number,
  login: Function,
  logout: Function,
});

const { address, balance } = toRefs(props);
</script>

<style scoped lang="sass">
.navbar
  display: flex
  border-bottom: 1px solid #2d2d2d
  color: #fff
  padding: 0 30px
  justify-content: space-between
.left-navbar
  display: flex
.logo
  margin-right: 30px
</style>
